define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-container", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/plugins/discourse-custom-wizard/discourse/mixins/subscription"], function (_exports, _component, _decorators, _subscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend(_subscription.default, (_dec = (0, _decorators.default)("subscribed"), _dec2 = (0, _decorators.default)("subscribed"), _dec3 = (0, _decorators.default)("subscribed"), (_obj = {
    classNameBindings: [":wizard-subscription-container", "subscribed"],
    subscribedIcon(subscribed) {
      return subscribed ? "check" : "times";
    },
    subscribedLabel(subscribed) {
      return `admin.wizard.subscription.${subscribed ? "subscribed" : "not_subscribed"}.label`;
    },
    subscribedTitle(subscribed) {
      return `admin.wizard.subscription.${subscribed ? "subscribed" : "not_subscribed"}.title`;
    }
  }, (_applyDecoratedDescriptor(_obj, "subscribedIcon", [_dec], Object.getOwnPropertyDescriptor(_obj, "subscribedIcon"), _obj), _applyDecoratedDescriptor(_obj, "subscribedLabel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "subscribedLabel"), _obj), _applyDecoratedDescriptor(_obj, "subscribedTitle", [_dec3], Object.getOwnPropertyDescriptor(_obj, "subscribedTitle"), _obj)), _obj)));
});