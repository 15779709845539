define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-cta", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/plugins/discourse-custom-wizard/discourse/mixins/subscription", "I18n"], function (_exports, _component, _decorators, _subscription, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend(_subscription.default, (_dec = (0, _decorators.default)("subscribed"), _dec2 = (0, _decorators.default)("subscribed"), _dec3 = (0, _decorators.default)("i18nKey"), _dec4 = (0, _decorators.default)("i18nKey"), (_obj = {
    tagName: "a",
    classNameBindings: [":btn", ":btn-pavilion-support", "subscriptionType"],
    attributeBindings: ["title"],
    i18nKey(subscribed) {
      return `admin.wizard.subscription.cta.${subscribed ? "subscribed" : "none"}`;
    },
    icon(subscribed) {
      return subscribed ? "far-life-ring" : "external-link-alt";
    },
    title(i18nKey) {
      return _I18n.default.t(`${i18nKey}.title`);
    },
    label(i18nKey) {
      return _I18n.default.t(`${i18nKey}.label`);
    },
    click() {
      window.open(this.subscriptionCtaLink, "_blank").focus();
    }
  }, (_applyDecoratedDescriptor(_obj, "i18nKey", [_dec], Object.getOwnPropertyDescriptor(_obj, "i18nKey"), _obj), _applyDecoratedDescriptor(_obj, "icon", [_dec2], Object.getOwnPropertyDescriptor(_obj, "icon"), _obj), _applyDecoratedDescriptor(_obj, "title", [_dec3], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "label", [_dec4], Object.getOwnPropertyDescriptor(_obj, "label"), _obj)), _obj)));
});