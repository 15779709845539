define("discourse/plugins/discourse-custom-wizard/discourse/components/modal/next-session-scheduled", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    class="next-session-time-modal"
    @title={{this.title}}
  >
    <DateTimeInput
      @date={{this.bufferedDateTime}}
      @onChange={{action "dateTimeChanged"}}
      @showTime="true"
      @clearable="true"
    />
    <div class="modal-footer">
      <DButton
        @action={{action "submit"}}
        class="btn-primary"
        @label="admin.wizard.after_time_modal.done"
        @disabled={{this.submitDisabled}}
      />
    </div>
  </DModal>
  */
  {
    "id": "g+Hau1pv",
    "block": "[[[8,[39,0],[[24,0,\"next-session-time-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[30,0,[\"title\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@date\",\"@onChange\",\"@showTime\",\"@clearable\"],[[30,0,[\"bufferedDateTime\"]],[28,[37,2],[[30,0],\"dateTimeChanged\"],null],\"true\",\"true\"]],null],[1,\"\\n  \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@disabled\"],[[28,[37,2],[[30,0],\"submit\"],null],\"admin.wizard.after_time_modal.done\",[30,0,[\"submitDisabled\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"date-time-input\",\"action\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/modal/next-session-scheduled.hbs",
    "isStrictMode": false
  });
  let NextSessionScheduledComponent = _exports.default = (_class = class NextSessionScheduledComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "bufferedDateTime", _descriptor, this);
      _defineProperty(this, "title", _I18n.default.t("admin.wizard.after_time_modal.title"));
      this.bufferedDateTime = this.args.model.dateTime ? moment(this.args.model.dateTime) : moment(Date.now());
    }
    get submitDisabled() {
      return moment().isAfter(this.bufferedDateTime);
    }
    submit() {
      const dateTime = this.bufferedDateTime;
      this.args.model.update(moment(dateTime).utc().toISOString());
      this.args.closeModal();
    }
    dateTimeChanged(dateTime) {
      this.bufferedDateTime = dateTime;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "bufferedDateTime", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "submit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dateTimeChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dateTimeChanged"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NextSessionScheduledComponent);
});