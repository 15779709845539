define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-composer-preview", ["exports", "@ember/component", "discourse/lib/load-oneboxes", "@ember/runloop", "discourse-common/lib/debounce", "pretty-text/upload-short-url", "discourse/lib/ajax", "discourse-common/utils/decorators"], function (_exports, _component, _loadOneboxes, _runloop, _debounce, _uploadShortUrl, _ajax, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.on)("init"), (_obj = {
    updatePreview() {
      if (this.isDestroyed) {
        return;
      }
      (0, _runloop.schedule)("afterRender", () => {
        if (this._state !== "inDOM" || !this.element) {
          return;
        }
        const $preview = $(this.element);
        if ($preview.length === 0) {
          return;
        }
        this.previewUpdated($preview);
      });
    },
    previewUpdated($preview) {
      // Paint oneboxes
      const paintFunc = () => {
        (0, _loadOneboxes.loadOneboxes)($preview[0], _ajax.ajax, null, null, this.siteSettings.max_oneboxes_per_post, true // refresh on every load
        );
      };

      (0, _debounce.default)(this, paintFunc, 450);

      // Short upload urls need resolution
      (0, _uploadShortUrl.resolveAllShortUrls)(_ajax.ajax, this.siteSettings, $preview[0]);
    }
  }, (_applyDecoratedDescriptor(_obj, "updatePreview", [_dec], Object.getOwnPropertyDescriptor(_obj, "updatePreview"), _obj)), _obj)));
});